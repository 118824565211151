import React from "react";
import { GetBudgetResolutionEnum, BudgetResponse } from "@norma-bi/bi-api";

export type BudgetContextType = {
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  years: number[];
  selectedBusinessId: string | null;
  setSelectedBusinessId: React.Dispatch<React.SetStateAction<string | null>>;
  budgetData: BudgetResponse | null;
  setBudgetData: React.Dispatch<React.SetStateAction<BudgetResponse | null>>;
  selectedResolution: GetBudgetResolutionEnum;
  setSelectedResolution: React.Dispatch<React.SetStateAction<GetBudgetResolutionEnum>>;
};

export const BudgetContext = React.createContext<BudgetContextType | undefined>(undefined);
