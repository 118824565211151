export function Trash() {
  return (
    <svg width="16" height="16" viewBox="0 1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="trash-can-l">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.55554 4.4445H12.4444L10.9629 14.2223H5.03702L3.55554 4.4445ZM3.55554 3.55561V1.77783H12.4444V3.55561H3.55554Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
