import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Action, AppEmbed, MessagePayload } from "@thoughtspot/visual-embed-sdk/react";
import { AppEmbed as AppEmbedType } from "@thoughtspot/visual-embed-sdk";
import { COMMON_HIDDEN_ACTIONS, EXPAND_ACTION } from "../../thoughtspot/constants";
import { useGetRuntimeFilters } from "../../thoughtspot/useGetRuntimeFilters";
import { ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE } from "../../constants";
import { UserAggregate } from "@norma-bi/bi-api";
import { getLiveboardIdFromPath, isLiveboardPath } from "../../ProLayout/utils";
import { useCaptureLiveboardView } from "../../hooks/useCaptureLiveboardView";
import { useAuth } from "../../Auth";
import { useThoughtSpotEmbedCommonProps } from "../../thoughtspot/embedding";

const baseHiddenActions: Action[] = [...COMMON_HIDDEN_ACTIONS, EXPAND_ACTION];

const getHiddenActions = (currentUser?: UserAggregate): Action[] => {
  if (currentUser?.is_root) {
    return baseHiddenActions;
  }
  return [...baseHiddenActions, Action.Schedule, Action.SchedulesList];
};

export function AppEmbedPage() {
  const commonProps = useThoughtSpotEmbedCommonProps();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const hiddenActions = React.useMemo(() => getHiddenActions(auth.user), [auth.user]);
  const navRef = React.useRef(navigate);

  const runtimeFilters = useGetRuntimeFilters();

  // NOTE(yannis): When any prop of <AppEmbed /> changes, the embedding  is re-initialized. This means, that when even the
  // path prop changes, a ton of rerenders take place. As a result, we use `initialPath` to set the first page which we
  // want the embedding to load. For example, if a user pastes a url of the form: `/pinboards/<pinboard-id>`, the embedding
  // should render this specific liveboard.
  // Further navigation changes happen using `appEmbedRef.navigateToPage()`.
  // However, when there are props that we expect to change, such as `runtimeFilters`, we cannot avoid the embedding
  // re-initialization. Subsequently, we need to change the `initialPath` state to the location at which the embedding was at
  // before the prop changes occur.
  const [initialPath, setInitialPath] = React.useState(location.pathname + location.search);
  const propsExpectedToChange = [runtimeFilters];
  React.useEffect(() => {
    setInitialPath(location.pathname + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...propsExpectedToChange]);
  const appEmbedRef = React.useRef<AppEmbedType>(null);

  React.useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  React.useEffect(() => {
    // this needs to run asynchronously of the render for the initial navigation
    const timeout = setTimeout(() => {
      if (!appEmbedRef.current) {
        return;
      }
      // NOTE(yannis): This prevents a weird behaviour that was introduced after a ThoughtSpot cluster upgrade where
      // navigating to paths that have `/embed` prefix was leading to an infinite navigation loop,
      // adding the suffix again in front of each new path.
      if (location.state?.[ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE]) {
        return;
      }
      appEmbedRef.current.navigateToPage(location.pathname + location.search);
    }, 0);
    return () => clearTimeout(timeout);
  }, [location]);

  const handleRouteChange = React.useCallback((evt: MessagePayload) => {
    // Use replace true so that we don't create a new history entry because iframes add a history entry anyway (without changing the path)
    navRef.current(evt.data.currentPath, {
      replace: true,
      state: { [ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE]: true },
    });
  }, []);

  /****** LIVEBOARD VIEW CAPTURING ******/

  const lastPathname = React.useRef<string | null>(null);
  const captureLiveboardView = useCaptureLiveboardView();

  React.useEffect(() => {
    const pathname = location.pathname;
    const pathnameDidNotChange = lastPathname.current === pathname;
    const liveboardIdFromPathname = getLiveboardIdFromPath(pathname);

    lastPathname.current = location.pathname;

    if (!isLiveboardPath(pathname) || pathnameDidNotChange || !liveboardIdFromPathname) {
      return;
    }
    captureLiveboardView(liveboardIdFromPathname);
  }, [location.pathname, captureLiveboardView]);

  /**************************************/

  return (
    <AppEmbed
      {...commonProps}
      ref={appEmbedRef}
      path={initialPath}
      className={"w-full h-full"}
      hiddenActions={hiddenActions}
      onRouteChange={handleRouteChange}
      dataPanelV2={true}
      // @ts-expect-error they don't export the HomePageSearchBarMode enum correctly
      homePageSearchBarMode={"aiAnswer"}
    />
  );
}
