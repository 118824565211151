import { useQuery } from "@tanstack/react-query";
import { demoUsers } from "../demo";
import { isAllBusiness, useAPI } from "../api";

export function useGetUsers(args: Partial<{ businessId: string; userIsDemo: boolean }>) {
  const api = useAPI();

  return useQuery({
    queryKey: ["users", args.businessId, args.userIsDemo],
    queryFn: async () => {
      if (args.userIsDemo) {
        return demoUsers;
      }

      if (isAllBusiness(args.businessId!)) {
        return (await api.getOrganizationUsers()).data;
      }

      return (
        await api.getUsersByBusiness({
          business_id: args.businessId!,
        })
      ).data;
    },
    enabled: api.isAuthenticated && Boolean(args.businessId),
  });
}
