import React from "react";

export type DialogButtonsFooterProps = {
  confirmText: React.ReactNode;
  onConfirm: () => void;
  isConfirmDisabled?: boolean;
  showConfirm?: boolean;
  cancelText?: React.ReactNode;
  onCancel?: () => void;
  isCancelDisabled?: boolean;
  containerClassName?: string;
  showCancel?: boolean;
};

export function DialogButtonsFooter({
  confirmText,
  onConfirm,
  isConfirmDisabled = false,
  showConfirm = true,
  cancelText,
  onCancel,
  isCancelDisabled = false,
  containerClassName = "",
  showCancel = true,
}: DialogButtonsFooterProps) {
  return (
    <div className={`w-full bg-white justify-end flex flex-row px-4 text-sm ${containerClassName}`}>
      {showCancel && onCancel && (
        <button
          className="thoughtspot-cancel-button mr-4"
          onClick={onCancel}
          disabled={isCancelDisabled}
        >
          {cancelText}
        </button>
      )}
      {showConfirm && (
        <button
          className="thoughtspot-main-button"
          onClick={onConfirm}
          disabled={isConfirmDisabled}
        >
          {confirmText}
        </button>
      )}
    </div>
  );
}
