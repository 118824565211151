import { Action } from "@thoughtspot/visual-embed-sdk";

/**
 * NOTE(Yannis):
 * This locale overrides the per-user locale that is assigned to each ThoughtSpot user.
 * This is probably temporary, since at some point we will need to support multiple locales.
 * The original reason for its existence was that ThoughtSpot assigned US locale to some users, which led to
 * MM/DD/YYYY rendering, which isn't appropriate for our customer base (Arabs).
 *
 * We used to rely on the biserver to set the locale to "en_GB" (with underscore) for each user whenever they logged in.
 * After the 2024-08-30 ThoughtSpot cluster upgrade, ThoughtSpot introduced a breaking change where "en_GB" was removed
 * from the possible locale values and, per our off-the-record discussion with them, they forgot to implement a fallback mechanism.
 * This broke date filter rendering and subsequently broke the app embedding.
 * The correct value after the upgrade is "en-GB".
 *
 * They also informed me of the `locale` prop's existence, which essentially means that we don't need to perform user
 * update operations on each login.
 *
 * That's the story of how and why this locale is defined here and given as a prop in `<AppEmbed />` and
 * `<LiveboardEmbed />`.
 */
export const LOCALE_OVERRIDE = "en-GB";

export const EXPAND_ACTION: Action = "expand" as Action;

export const COMMON_HIDDEN_ACTIONS: Action[] = [
  Action.MakeACopy,
  Action.ShowUnderlyingData,
  Action.RequestVerification,
  Action.EditTML,
  Action.ExportTML,
  Action.UpdateTML,
  Action.ImportTML,
  Action.PinboardInfo,
  Action.AIHighlights,
];
