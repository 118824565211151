export function EmptyBox() {
  return (
    <svg
      width="247"
      height="247"
      viewBox="0 0 247 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fi_7486744">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M107.349 79.669C110.448 78.4379 113.975 77.5888 117.889 77.1952C118.95 77.091 119.722 76.1454 119.618 75.0841C119.51 74.0266 118.564 73.2509 117.507 73.3551C113.204 73.7873 109.329 74.729 105.925 76.0837C104.933 76.4735 104.451 77.5965 104.844 78.5884C105.238 79.5764 106.361 80.0627 107.349 79.669Z"
          fill="black"
        />
        <path
          id="Vector_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M127.343 77.1215C131.905 77.465 136.081 77.5383 139.894 77.3762C140.959 77.3337 141.789 76.4345 141.743 75.3693C141.7 74.3041 140.797 73.4782 139.736 73.5207C136.058 73.6751 132.033 73.6017 127.633 73.2698C126.572 73.1926 125.645 73.9877 125.564 75.049C125.483 76.1142 126.282 77.0404 127.343 77.1215Z"
          fill="black"
        />
        <path
          id="Vector_3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M150.315 76.2346C154.815 75.3894 158.632 74.139 161.839 72.5991C162.8 72.136 163.205 70.982 162.742 70.0249C162.283 69.0639 161.129 68.6587 160.168 69.1179C157.227 70.5305 153.726 71.669 149.601 72.4447C148.555 72.6416 147.864 73.6488 148.061 74.6986C148.258 75.7445 149.269 76.4315 150.315 76.2346Z"
          fill="black"
        />
        <path
          id="Vector_4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M169.705 67.2378C173.163 63.8995 175.193 60.0555 176.108 56.146C176.351 55.1078 175.703 54.0696 174.668 53.8265C173.63 53.5833 172.592 54.2317 172.349 55.266C171.592 58.5118 169.894 61.6919 167.027 64.4591C166.259 65.2001 166.236 66.4235 166.977 67.1876C167.718 67.9557 168.937 67.9788 169.705 67.2378Z"
          fill="black"
        />
        <path
          id="Vector_5"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M175.188 42.8582C173.656 38.7904 171.058 35.3363 167.893 33.1518C167.017 32.5498 165.817 32.7698 165.211 33.6458C164.605 34.5219 164.825 35.726 165.705 36.332C168.264 38.0957 170.336 40.9208 171.575 44.2167C171.95 45.2124 173.061 45.718 174.061 45.3436C175.056 44.9693 175.562 43.8539 175.188 42.8582Z"
          fill="black"
        />
        <path
          id="Vector_6"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M124.908 33.1445C127.027 23.2028 135.471 18.367 141.561 18.3323C143.56 18.3207 145.293 18.7915 146.192 19.9957C146.725 20.7096 146.887 21.6668 146.682 22.84C146.501 23.8898 147.207 24.8893 148.257 25.0707C149.307 25.2521 150.306 24.5459 150.488 23.4961C150.904 21.084 150.38 19.1505 149.284 17.6877C147.751 15.6307 144.942 14.4536 141.542 14.4729C134.128 14.5153 123.711 20.2388 121.133 32.3418C120.909 33.3838 121.573 34.4066 122.615 34.6304C123.657 34.8542 124.684 34.1866 124.908 33.1445Z"
          fill="black"
        />
        <path
          id="Vector_7"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M121.149 33.1524C123.762 45.1937 134.14 50.8901 141.534 50.9364C145.197 50.9557 148.165 49.5934 149.616 47.2353C150.492 45.815 150.874 44.005 150.465 41.7936C149.963 39.0727 148.172 35.5877 144.425 31.4504C143.711 30.6593 142.488 30.5975 141.7 31.3115C140.909 32.0293 140.847 33.2489 141.565 34.0401C144.174 36.923 145.722 39.3815 146.401 41.4462C146.914 43.0208 146.903 44.2829 146.331 45.213C145.814 46.0505 144.888 46.556 143.773 46.8339C142.372 47.1813 140.693 47.1542 138.921 46.7799C133.26 45.5835 126.768 40.848 124.924 32.3342C124.696 31.2922 123.669 30.6323 122.627 30.8561C121.585 31.0838 120.925 32.1104 121.149 33.1524Z"
          fill="black"
        />
        <path
          id="Vector_8"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M122.512 36.6025H148.35C150.481 36.6025 152.21 34.8735 152.21 32.7432C152.21 30.6128 150.481 28.8838 148.35 28.8838H122.512C120.381 28.8838 118.652 30.6128 118.652 32.7432C118.652 34.8735 120.381 36.6025 122.512 36.6025Z"
          fill="#A38EDE"
        />
        <path
          id="Vector_9"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M44.3828 187.257V208.904C44.3828 213.165 47.177 216.92 51.2602 218.144C66.7672 222.798 110.386 235.885 120.729 238.984C122.535 239.529 124.465 239.529 126.271 238.984C136.614 235.885 180.233 222.798 195.74 218.144C199.823 216.92 202.617 213.165 202.617 208.904C202.617 198.6 202.617 178.041 202.617 178.041C202.617 176.976 201.753 176.111 200.688 176.111C199.622 176.111 198.758 176.976 198.758 178.041V208.904C198.758 211.459 197.079 213.713 194.632 214.45C179.125 219.101 135.503 232.188 125.163 235.291C124.079 235.615 122.921 235.615 121.837 235.291L52.3679 214.45C49.921 213.713 48.2422 211.459 48.2422 208.904V187.257C48.2422 186.192 47.3777 185.328 46.3125 185.328C45.2473 185.328 44.3828 186.192 44.3828 187.257Z"
          fill="black"
        />
        <path
          id="Vector_10"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.8913 143.53C39.8913 143.53 30.1194 158.019 25.1253 165.421C24.1103 166.926 23.8594 168.825 24.4538 170.542C25.0443 172.26 26.4066 173.603 28.1356 174.162L97.5851 196.728C100.04 197.523 102.722 196.605 104.169 194.47C109.345 186.829 125.099 163.588 125.099 163.588C125.438 163.086 125.523 162.453 125.326 161.878C125.129 161.303 124.674 160.855 124.095 160.67L46.9076 135.623C45.8965 135.295 44.8081 135.85 44.4762 136.865C44.1482 137.876 44.7039 138.965 45.7189 139.297L120.463 163.549L100.974 192.305C100.491 193.015 99.5958 193.324 98.7776 193.058L29.3282 170.492C28.7531 170.303 28.2977 169.859 28.1009 169.284C27.9041 168.713 27.989 168.08 28.3247 167.578L43.0907 145.688C43.6889 144.804 43.4535 143.604 42.5697 143.009C41.6898 142.411 40.4895 142.647 39.8913 143.53Z"
          fill="black"
        />
        <path
          id="Vector_11"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M189.113 139.184L122.905 160.669C121.89 160.997 121.335 162.086 121.663 163.101C121.995 164.112 123.083 164.668 124.094 164.339L190.302 142.858C191.317 142.53 191.872 141.438 191.544 140.427C191.212 139.412 190.124 138.856 189.113 139.184Z"
          fill="black"
        />
        <path
          id="Vector_12"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M127.368 171.649C127.368 171.649 138.564 188.168 142.832 194.47C144.279 196.604 146.962 197.523 149.416 196.728C160.798 193.03 204.204 178.924 218.866 174.162C220.595 173.602 221.957 172.259 222.548 170.542C223.142 168.824 222.891 166.926 221.876 165.42C215.825 156.447 202.29 136.379 202.29 136.379C201.691 135.499 200.491 135.263 199.607 135.858C198.728 136.456 198.492 137.656 199.086 138.54C199.086 138.54 212.625 158.605 218.677 167.578C219.012 168.08 219.097 168.713 218.9 169.284C218.704 169.859 218.248 170.303 217.673 170.492L148.224 193.057C147.406 193.324 146.51 193.015 146.028 192.305L130.563 169.484C129.965 168.601 128.765 168.373 127.881 168.971C127.001 169.565 126.769 170.77 127.368 171.649Z"
          fill="black"
        />
        <path
          id="Vector_13"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M58.6317 135.479L124.098 114.168C124.673 113.979 125.129 113.531 125.326 112.956C125.522 112.381 125.437 111.748 125.094 111.246C125.094 111.246 111.231 90.8574 106.411 83.7677C104.963 81.6412 102.285 80.7227 99.8344 81.5177L30.381 104.083C28.652 104.647 27.2897 105.986 26.6953 107.707C26.1049 109.425 26.3557 111.32 27.3707 112.829C32.9437 121.092 44.7109 138.54 44.7109 138.54C45.3091 139.42 46.5094 139.655 47.3932 139.057C48.2731 138.463 48.5085 137.263 47.9142 136.379C47.9142 136.379 36.1431 118.934 30.5702 110.671C30.2305 110.166 30.1495 109.537 30.3463 108.962C30.5431 108.391 30.9985 107.943 31.5736 107.754L101.027 85.188C101.845 84.9217 102.737 85.2304 103.219 85.9367L120.459 111.293L57.4391 131.809C56.4241 132.137 55.8722 133.229 56.2003 134.241C56.5322 135.252 57.6205 135.808 58.6317 135.479Z"
          fill="black"
        />
        <path
          id="Vector_14"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M122.901 114.168L200.089 139.293C200.907 139.559 201.806 139.254 202.289 138.54C202.289 138.54 214.056 121.092 219.629 112.829C220.644 111.32 220.895 109.425 220.304 107.707C219.71 105.986 218.348 104.647 216.619 104.083L147.165 81.5177C144.715 80.7227 142.036 81.6412 140.589 83.7677L127.089 103.62C126.491 104.504 126.718 105.704 127.598 106.303C128.482 106.901 129.682 106.673 130.281 105.793L143.781 85.9367C144.263 85.2304 145.155 84.9217 145.973 85.188L215.426 107.754C216.001 107.943 216.457 108.391 216.653 108.962C216.85 109.537 216.769 110.166 216.43 110.671L199.9 135.175L124.098 110.498C123.083 110.166 121.994 110.722 121.666 111.737C121.335 112.748 121.89 113.836 122.901 114.168Z"
          fill="black"
        />
        <path
          id="Vector_15"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M181.959 137.451L123.501 156.416L65.043 137.451L123.501 118.421L181.959 137.451Z"
          fill="#A38EDE"
        />
        <path
          id="Vector_16"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.196 112.153C103.158 110.691 102.266 109.22 101.518 107.75C101.035 106.8 99.8734 106.422 98.924 106.908C97.9746 107.391 97.5964 108.553 98.0788 109.502C98.9086 111.134 99.8966 112.767 101.051 114.392C101.668 115.26 102.872 115.461 103.741 114.847C104.609 114.23 104.813 113.022 104.196 112.153Z"
          fill="black"
        />
        <path
          id="Vector_17"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M98.8421 99.7228C98.4948 97.523 98.4794 95.3733 98.7843 93.3124C98.9386 92.2588 98.2092 91.2785 97.1556 91.1241C96.102 90.9698 95.1217 91.6992 94.9635 92.7528C94.6084 95.1842 94.62 97.7237 95.0291 100.321C95.195 101.375 96.183 102.092 97.2366 101.927C98.2864 101.761 99.0081 100.773 98.8421 99.7228Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
