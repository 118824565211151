export function Upload() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="upload-l">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.82444 8.71982L7.60222 6.94205V15.9998H9.37999V6.94205L11.1578 8.71982L12.4111 7.46649L8.49111 3.54649L4.57111 7.46649L5.82444 8.71982ZM14.7222 0.888672V4.44423H12.0555C12.0555 3.55532 11.1755 1.77756 8.49999 1.77756C5.82444 1.77756 4.94444 3.55532 4.94444 4.44423H2.27777V0.888672H14.7222Z"
          fill="#440099"
        />
      </g>
    </svg>
  );
}
