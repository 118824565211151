export function File() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="note-l">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3333 0.888672H2.66663V15.1109H10.6666L13.3333 12.4442V0.888672ZM9.77774 10.6664H4.4444V12.4442H9.77774V10.6664ZM4.4444 7.11089H11.5555V8.88867H4.4444V7.11089ZM11.5555 3.55534H4.4444V5.33312H11.5555V3.55534Z"
          fill="#777E8B"
        />
      </g>
    </svg>
  );
}
