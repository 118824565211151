import React from "react";
import { BaseSettingsTable } from "../components/BaseSettingsTable";
import { ErrorDoodle } from "../../../components/ErrorDoodle";
import { ColumnDef, CellContext } from "@tanstack/react-table";
import { GetBudgetResolutionEnum } from "@norma-bi/bi-api";
import { Loader } from "../../../components/Loader";
import { useGetBudget } from "../../../hooks/useGetBudget";
import "./index.css";

type TableData = {
  venue_name: string;
  [key: string]: string | number;
};

type SettingsBudgetTableProps = {
  businessId: string;
  year: number;
  resolution: GetBudgetResolutionEnum;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const generateWeeks = (count: number) => {
  return Array.from({ length: count }, (_, i) => `Week ${i + 1}`);
};

const formatCurrency = (value: number) => {
  return value.toFixed(0);
};

const createColumns = (
  resolution: (typeof GetBudgetResolutionEnum)[keyof typeof GetBudgetResolutionEnum],
): ColumnDef<TableData>[] => {
  const baseColumn = [
    {
      header: "Branch",
      accessorKey: "venue_name",
    },
  ];

  const periods = resolution === GetBudgetResolutionEnum.weekly ? generateWeeks(52) : months;

  const periodColumns = periods.map((period, index) => ({
    header: resolution === GetBudgetResolutionEnum.weekly ? period : period.slice(0, 3),
    accessorKey: `period${index + 1}`,
    cell: ({ getValue }: CellContext<TableData, unknown>) => {
      const value = getValue();
      return formatCurrency(value as number);
    },
  }));

  return [...baseColumn, ...periodColumns];
};

export function SettingsBudgetTable({ businessId, year, resolution }: SettingsBudgetTableProps) {
  const budget = useGetBudget(businessId, resolution, year);

  const columns = React.useMemo(() => createColumns(resolution), [resolution]);

  const tableData = React.useMemo(() => {
    if (!budget.data) return [];
    return budget.data.venue_budgets.map((venue) => {
      const rowData: TableData = {
        venue_name: venue.venue_name,
      };
      venue.budget_values.forEach((value, index) => {
        rowData[`period${index + 1}`] = value;
      });
      return rowData;
    });
  }, [budget.data]);

  const dataPeriodsCount = budget.data?.venue_budgets[0]?.budget_values.length || 0;
  const columnsPeriodsCount = columns.length - 1;

  if (budget.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-16 gap-10">
        <Loader />
      </div>
    );
  }

  if (budget.isError) {
    return (
      <div className="flex flex-col items-center justify-center p-16 gap-10">
        <ErrorDoodle />
        <div>
          <div className="text-3xl font-medium text-sematicRed text-center">ERROR</div>
          <div className="text-lg font-medium text-feintGray text-center">
            Please try refreshing the page
          </div>
        </div>
      </div>
    );
  }

  if (dataPeriodsCount !== columnsPeriodsCount) {
    return (
      <div className="flex flex-col items-center justify-center p-16 gap-10">
        <Loader />
      </div>
    );
  }

  if (!tableData || tableData.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-16 gap-10">
        <ErrorDoodle />
        <div>
          <div className="text-3xl font-medium text-sematicRed text-center">ERROR</div>
          <div className="text-lg font-medium text-feintGray text-center">
            Please try refreshing the page
          </div>
        </div>
      </div>
    );
  }

  return (
    <BaseSettingsTable
      data={tableData}
      columns={columns}
      tableClassName="settings-budget-table settings-table"
    />
  );
}
