export function Download() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="download-l">
        <g id="Path">
          <path
            d="M10.6755 7.28L8.89775 9.05778V0H7.11997V9.05778L5.34219 7.28L4.08886 8.53333L8.00886 12.4533L11.9289 8.53333L10.6755 7.28Z"
            fill="#440099"
          />
          <path d="M14.2222 13.3333H1.77777V15.1111H14.2222V13.3333Z" fill="#440099" />
        </g>
      </g>
    </svg>
  );
}
