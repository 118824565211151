import React from "react";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { SvgIcon } from "../../../svg";
import { useBusinessContext } from "../../../hooks/useBusinessContext";
import { useGetBusinesses } from "../../../hooks/useGetBusinesses";
import { Business } from "@norma-bi/bi-api";
import { useBudgetContext } from "./useBudgetContext";
import { BudgetDialog } from "./BudgetDialog";
import clsx from "clsx";
import { GetBudgetResolutionEnum } from "@norma-bi/bi-api";

const RESOLUTION_OPTIONS = [
  { id: GetBudgetResolutionEnum.monthly, label: "Monthly" },
  { id: GetBudgetResolutionEnum.weekly, label: "Weekly" },
] as const;

const getResolutionLabel = (resolution: GetBudgetResolutionEnum): string => {
  return RESOLUTION_OPTIONS.find((option) => option.id === resolution)?.label ?? resolution;
};

export function BudgetHeader() {
  const [showEditDialog, setShowEditDialog] = React.useState(false);

  const {
    selectedYear,
    setSelectedYear,
    years,
    setSelectedBusinessId,
    budgetData,
    selectedResolution,
    setSelectedResolution,
  } = useBudgetContext();
  const { data: businesses } = useGetBusinesses();
  const { business, setBusiness } = useBusinessContext();

  const handleBusinessSelect = (businessId: string) => {
    setBusiness(businessId);
    setSelectedBusinessId(businessId);
  };

  const showResolutionAndEdit = budgetData && budgetData.venue_budgets.length > 0;

  return (
    <div className="flex justify-between items-start mb-6">
      <div className="flex flex-col gap-2 text-dark">
        <h1 className="text-2xl font-semibold">Budget</h1>
        <div className="text-sm">
          <p>Please note that you can only compare the budget to Net Sales, gross sales and net</p>
          <p>sales with tax. If you want to compare it with any other values, please contact us.</p>
        </div>
      </div>

      <div className="flex gap-4">
        <Menu as="div" className="relative inline-block w-fit">
          <MenuButton className="inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border-b border-dark/90 hover:bg-gray-50">
            {business?.name || "Select Business"}
            <SvgIcon icon="ChevronDown" />
          </MenuButton>

          <MenuItems className="absolute right-0 z-10 mt-2 p-2 min-w-full origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {businesses?.map((item: Business) => (
                <MenuItem
                  key={item.id}
                  as="a"
                  href="#"
                  onClick={() => handleBusinessSelect(item.id)}
                  className={clsx(
                    "block w-full px-4 py-2 text-sm text-feintGray hover:bg-backgroundGray hover:text-dark hover:font-semibold whitespace-nowrap",
                    item.id === business?.id && "font-semibold !text-dark",
                  )}
                >
                  {item.name}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Menu>

        <Menu as="div" className="relative inline-block w-fit">
          <MenuButton className="inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border-b border-dark/90 hover:bg-gray-50">
            {selectedYear}
            <SvgIcon icon="ChevronDown" />
          </MenuButton>
          <MenuItems className="absolute right-0 z-10 mt-2 p-2 min-w-full origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {years.map((year) => (
                <MenuItem
                  key={year}
                  as="a"
                  href="#"
                  onClick={() => setSelectedYear(year)}
                  className={clsx(
                    "block w-full px-4 py-2 text-sm text-feintGray hover:bg-backgroundGray hover:text-dark hover:font-semibold whitespace-nowrap",
                    year === selectedYear && "font-semibold !text-dark",
                  )}
                >
                  {year}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Menu>
        {showResolutionAndEdit && (
          <>
            <Menu as="div" className="relative inline-block w-fit">
              <MenuButton className="inline-flex items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border-b border-dark/90 hover:bg-gray-50">
                {getResolutionLabel(selectedResolution)}
                <SvgIcon icon="ChevronDown" />
              </MenuButton>
              <MenuItems className="absolute right-0 z-10 mt-2 p-2 min-w-full origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {RESOLUTION_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.id}
                      as="a"
                      href="#"
                      onClick={() => setSelectedResolution(option.id)}
                      className={clsx(
                        "block w-full px-4 py-2 text-sm text-feintGray hover:bg-backgroundGray hover:text-dark hover:font-semibold whitespace-nowrap",
                        option.id === selectedResolution && "font-semibold !text-dark",
                      )}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Menu>
            <button
              onClick={() => setShowEditDialog(true)}
              className="text-white bg-secondaryColor rounded-full px-6 py-2 text-sm"
            >
              Edit
            </button>
            <BudgetDialog
              show={showEditDialog}
              mode="edit"
              onClose={() => setShowEditDialog(false)}
              businessId={business?.id || ""}
              year={selectedYear}
            />
          </>
        )}
      </div>
    </div>
  );
}
