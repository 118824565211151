import "./index.css";
import { SettingsSidebar } from "./SettingsSidebar";
import { Outlet } from "react-router";
import { SIDEBAR_GROUPS } from "./sidebarGroups";

export function Settings() {
  return (
    <div className="flex bg-lightGray h-full">
      <SettingsSidebar groups={SIDEBAR_GROUPS} />
      <div className="flex-1 h-full p-10 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}
