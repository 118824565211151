/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BranchGeneralMetrics
 */
export interface BranchGeneralMetrics {
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    net_sales_with_tax: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    net_sales: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    order_count: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    spend_per_order: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    return_amount: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    void_amount: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    discount_amount: number;
    /**
     * 
     * @type {number}
     * @memberof BranchGeneralMetrics
     */
    payment_amount: number;
}

/**
 * Check if a given object implements the BranchGeneralMetrics interface.
 */
export function instanceOfBranchGeneralMetrics(value: object): value is BranchGeneralMetrics {
    if (!('net_sales_with_tax' in value) || value['net_sales_with_tax'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    if (!('order_count' in value) || value['order_count'] === undefined) return false;
    if (!('spend_per_order' in value) || value['spend_per_order'] === undefined) return false;
    if (!('return_amount' in value) || value['return_amount'] === undefined) return false;
    if (!('void_amount' in value) || value['void_amount'] === undefined) return false;
    if (!('discount_amount' in value) || value['discount_amount'] === undefined) return false;
    if (!('payment_amount' in value) || value['payment_amount'] === undefined) return false;
    return true;
}

export function BranchGeneralMetricsFromJSON(json: any): BranchGeneralMetrics {
    return BranchGeneralMetricsFromJSONTyped(json, false);
}

export function BranchGeneralMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchGeneralMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        'net_sales_with_tax': json['net_sales_with_tax'],
        'net_sales': json['net_sales'],
        'order_count': json['order_count'],
        'spend_per_order': json['spend_per_order'],
        'return_amount': json['return_amount'],
        'void_amount': json['void_amount'],
        'discount_amount': json['discount_amount'],
        'payment_amount': json['payment_amount'],
    };
}

export function BranchGeneralMetricsToJSON(json: any): BranchGeneralMetrics {
    return BranchGeneralMetricsToJSONTyped(json, false);
}

export function BranchGeneralMetricsToJSONTyped(value?: BranchGeneralMetrics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'net_sales_with_tax': value['net_sales_with_tax'],
        'net_sales': value['net_sales'],
        'order_count': value['order_count'],
        'spend_per_order': value['spend_per_order'],
        'return_amount': value['return_amount'],
        'void_amount': value['void_amount'],
        'discount_amount': value['discount_amount'],
        'payment_amount': value['payment_amount'],
    };
}

