import { useContext } from "react";
import { BudgetContext, BudgetContextType } from "./BudgetContext";

export function useBudgetContext(): BudgetContextType {
  const context = useContext(BudgetContext);
  if (context === undefined) {
    throw new Error("useBudgetContext must be used within a BudgetProvider");
  }
  return context;
}
