import { Products } from "./Products";
import { Sales } from "./Sales";
import { Shifts } from "./Shifts";
import { Settings } from "./Settings";
import { ChevronDown } from "./ChevronDown";
import { Check } from "./Check";
import { Dashboard } from "./Dasbhoard";
import { SearchData } from "./SearchData";
import { Reports } from "./Reports";
import { Heart } from "./Heart";
import { CompanyLogo } from "./CompanyLogo";
import { CompanyLogoLogin } from "./CompanyLogoLogin";
import { Logout } from "./Logout";
import { Search } from "./Search";
import { ArrowDown } from "./ArrowDown";
import { Plus } from "./Plus";
import { Home } from "./Home";
import { Answers } from "./Answers";
import { Liveboards } from "./Liveboards";
import { SpotIQ } from "./SpotIQ";
import { UpDownArrows } from "./UpDownArrows";
import { More } from "./More";
import { Cross } from "./Cross";
import { FavoriteEmpty } from "./FavoriteEmtpy";
import { FavoriteFilled } from "./FavoriteFilled";
import { Liveboard } from "./Liveboard";
import { Edit } from "./Edit";
import { CheckboxDefault } from "./CheckboxDefault";
import { CheckboxTicked } from "./CheckboxTicked";
import { Info } from "./Info";
import { MaintenanceTrip } from "./MaintenanceTrip";
import { Star } from "./Star";
import { SortHandle } from "./SortHandle";
import { CaretDown } from "./CaretDown";
import { DoubleChevronLeft } from "./DoubleChevronLeft";
import { Download } from "./Download";
import { Upload } from "./Upload";
import { File } from "./File";
import { Trash } from "./Trash";

const svgIconRegistry = {
  Sales,
  Shifts,
  Products,
  Settings,
  ChevronDown,
  Check,
  Dashboard,
  SearchData,
  Reports,
  Heart,
  CompanyLogo,
  CompanyLogoLogin,
  Logout,
  Search,
  ArrowDown,
  Plus,
  Home,
  Answers,
  Liveboards,
  SpotIQ,
  UpDownArrows,
  More,
  Cross,
  FavoriteEmpty,
  FavoriteFilled,
  Liveboard,
  Edit,
  CheckboxDefault,
  CheckboxTicked,
  Info,
  MaintenanceTrip,
  Star,
  SortHandle,
  CaretDown,
  DoubleChevronLeft,
  Download,
  Upload,
  File,
  Trash,
} as const;

export type SvgIconType = keyof typeof svgIconRegistry;

export type SvgIconProps = {
  icon: SvgIconType;
};

export function SvgIcon(props: SvgIconProps) {
  return svgIconRegistry[props.icon]();
}
