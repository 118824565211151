import React from "react";
import { GetBudgetResolutionEnum, BudgetResponse } from "@norma-bi/bi-api";
import { BudgetContext, BudgetContextType } from "./BudgetContext";

export function BudgetProvider({ children }: React.PropsWithChildren) {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 12 }, (_, i) => currentYear - 1 + i);
  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [selectedBusinessId, setSelectedBusinessId] = React.useState<string | null>(null);
  const [budgetData, setBudgetData] = React.useState<BudgetResponse | null>(null);
  const [selectedResolution, setSelectedResolution] = React.useState<GetBudgetResolutionEnum>(
    GetBudgetResolutionEnum.monthly,
  );

  const value = React.useMemo<BudgetContextType>(
    () => ({
      selectedYear,
      setSelectedYear,
      years,
      selectedBusinessId,
      setSelectedBusinessId,
      budgetData,
      setBudgetData,
      selectedResolution,
      setSelectedResolution,
    }),
    [selectedYear, years, selectedBusinessId, budgetData, selectedResolution],
  );

  return <BudgetContext.Provider value={value}>{children}</BudgetContext.Provider>;
}
