import * as Sentry from "@sentry/react";
import { createBrowserRouter, Navigate, RouteObject } from "react-router";
import { RouterProvider } from "react-router/dom";
import { Root } from "./Root";
import { Protected } from "./Protected";
import { Login } from "./pages/Login/Login";
import { DynamicLayout } from "./DynamicLayout";
import { AppEmbedPage } from "./pages/AppEmbed";
import { InitialOnboarding } from "./pages/Onboarding/InitialOnboarding";
import { OnboardUsersInNewOrganization } from "./pages/Onboarding/InitialOnboarding/OnboardUsersInNewOrganization";
import { AlertUsers } from "./pages/Onboarding/InitialOnboarding/AlertUsers";
import { ResetPassword } from "./pages/ResetPassword";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Settings } from "./pages/Settings";
import { RequireRoot } from "./guards/RequireRoot";
import { Welcome } from "./pages/Onboarding/InitialOnboarding/Welcome";
import { BusinessContext } from "./guards/BusinessContext";
import { SettingsUsers } from "./pages/Settings/Users";
import { SettingsBusinesses } from "./pages/Settings/Businesses";
import { SettingsEmailSchedules } from "./pages/Settings/EmailSchedules";
import { SettingsBudget } from "./pages/Settings/Budget";
import { PostOnboardingRedirectsHandler } from "./pages/Onboarding/PostOnboardingRedirectsHandler";
import { OnboardingInExistingOrganization } from "./pages/Onboarding/OnboardingInExistingOrganization";
import { OnboardUsersInExistingOrganization } from "./pages/Onboarding/OnboardingInExistingOrganization/OnboardUsersInExistingOrganization";
import { Done as DoneOnboardingInExistingOrg } from "./pages/Onboarding/OnboardingInExistingOrganization/Done";
import { Start as StartOnboardingInExistingOrg } from "./pages/Onboarding/OnboardingInExistingOrganization/Start";
import { Unprotected } from "./Unprotected";
import { MaintenanceGuard } from "./guards/MaintenanceGuard";
import { ErrorBoundary } from "./ErrorBoundary";
import { SearchEmbedPage } from "./pages/SearchEmbed";

// eslint-disable-next-line react-refresh/only-export-components
export const routes: RouteObject[] = [
  {
    path: "/",
    errorElement: <ErrorBoundary />,
    HydrateFallback: ErrorBoundary,
    element: (
      <MaintenanceGuard>
        <Root />
      </MaintenanceGuard>
    ),
    children: [
      {
        path: "login",
        element: (
          <Unprotected>
            <Login />
          </Unprotected>
        ),
        children: [
          {
            path: "forgot-password",
            element: <ForgotPassword />,
          },
        ],
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "already-installed",
        element: <PostOnboardingRedirectsHandler />,
      },
      {
        path: "multi-business/success",
        element: <PostOnboardingRedirectsHandler />,
      },
      {
        path: "multi-business/already-installed",
        element: <PostOnboardingRedirectsHandler />,
      },
      {
        path: "initial-onboarding",
        element: <InitialOnboarding />,
        children: [
          {
            index: true,
            element: <Navigate to={"/initial-onboarding/welcome"} replace />,
          },
          {
            path: "welcome",
            element: <Welcome />,
          },
          {
            path: "choose-users",
            element: <OnboardUsersInNewOrganization />,
          },
          {
            path: "alert-users",
            element: <AlertUsers />,
          },
        ],
      },
      {
        path: "onboarding/:businessId",
        element: <OnboardingInExistingOrganization />,
        children: [
          {
            index: true,
            element: <Navigate to={"start"} replace />,
          },
          {
            path: "start",
            element: <StartOnboardingInExistingOrg />,
          },
          {
            path: "users",
            element: <OnboardUsersInExistingOrganization />,
          },
          {
            path: "done",
            element: <DoneOnboardingInExistingOrg />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <Protected thoughtspot>
            <BusinessContext>
              <DynamicLayout />
            </BusinessContext>
          </Protected>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/home" replace />,
          },
          {
            path: "settings",
            element: (
              <RequireRoot>
                <Settings />
              </RequireRoot>
            ),
            children: [
              {
                index: true,
                element: <Navigate to={"/settings/users"} replace />,
              },
              {
                path: "users",
                element: <SettingsUsers />,
              },
              {
                path: "businesses",
                element: <SettingsBusinesses />,
              },
              {
                path: "email_schedules",
                element: <SettingsEmailSchedules />,
              },
              {
                path: "budget",
                element: <SettingsBudget />,
              },
            ],
          },
          {
            path: "/answer",
            element: <SearchEmbedPage />,
          },
          {
            path: "*",
            children: [
              {
                path: "*",
                element: <AppEmbedPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)(routes);

export function Router() {
  return <RouterProvider router={router} />;
}
